import { JSX, useState } from "react";
import { useNavigate } from "react-router-dom";

import { clsx } from "@/components/ui";
import { Heading, Paragraph } from "@/components/ui/typography";
import { FieldProps } from "@/components/Campaigns/CreateCampaign/fields/BaseField";

type StepComponent = (props: FieldProps) => JSX.Element;
type Step = StepComponent | [StepComponent, unknown?];

type ChatWizardProps = {
  title: string;
  steps: Step[];
  className?: string;
  onFinish: () => void;
};

export function ChatWizard({ title, steps, className, onFinish }: ChatWizardProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onFinish();
    }
  };

  return (
    <div className={clsx("space-y-[46px]", className)}>
      <div className="flex items-start justify-between">
        <Heading.h3 className="text-[32px]">{title}</Heading.h3>
        {steps.length > 1 && (
          <Paragraph weight="semi" size="u1">
            Question {currentStep + 1}/{steps.length}
          </Paragraph>
        )}
      </div>
      <div className="flex flex-col-reverse gap-y-[46px]">
        {steps.slice(0, currentStep + 1).map((step, index) => {
          const [Component, customProps] = Array.isArray(step) ? step : [step, null];
          return (
            <Component
              key={`step${index}`}
              {...(customProps || {})}
              active={index === currentStep}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          );
        })}
      </div>
    </div>
  );
}
