import LearnMore from "@/components/legacy/LearnMore";
import ContainerWithAmdBlock from "@/components/ContainerWithAmdBlock";

export default function LearnMorePage() {
  return (
    <ContainerWithAmdBlock className="py-10 text-white">
      <LearnMore />
    </ContainerWithAmdBlock>
  );
}
