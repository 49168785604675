import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container } from "react-bootstrap";

import { useData } from "@/hooks/useData";
import { Heading, Paragraph } from "@/components/ui/typography";

const Cases = () => (
  <Card className="card">
    <Card.Body>
      <Paragraph className="mb-0">
        1. Create an online ad <strong>quickly and easily</strong>.
        <br />
        2. Pay only when people click your ad.
        <br />
        3. <strong>Attract more customers</strong> to your website or Google Maps listing.
        <br />
        4. Minimal ongoing management necessary. <strong>Google Ads runs your ads for you</strong>.
        <br />
        5. Reach customers on desktop computers and mobile devices (such as mobile phones and tablets).
        <br />
        6. Review the effectiveness of your ads in your dashboard.
      </Paragraph>
    </Card.Body>
  </Card>
);

const LearnMore = () => {
  const navigate = useNavigate();
  const { token, refreshToken } = useData();

  // Manage state of component, so it shows when user clicks it
  const [showCases, setShowCases] = useState(false);

  // Component that has the list of use cases

  // START button
  const goStart = () => {
    // if there is a refresh token in the cookies,
    // send user to the Accounts page.
    if (refreshToken) {
      navigate("/googleads-accounts");
    }
    // if no refresh token but yes mytoken,
    // send user to Google Ads page.
    else if (token) {
      navigate("/googleads");
    }
    // if neither, this means user is not logged in yet,
    // so push user to the login or signup page.
    else {
      navigate("/login");
    }
  };

  // HOME button
  const goHome = () => {
    navigate("/");
  };

  return (
    <Container className="mt-4">
      <Heading.h4 className="mb-4 text-left">Why use Google Ads?</Heading.h4>

      <Paragraph className="mb-0">
        Google Ads allows you to take advantage of the benefits of online advertising: show your ads to the
        <strong className="text-orange">right people, in the right place, and at the right time.</strong>
        <br />
        <br />
        <br />
        When you sign up for a Smart campaign, you’ll write an ad that describes your business. You’ll also choose which
        keyword themes you want to target your ad and set a budget. Your ad will automatically show to potential
        customers across Google Search, Google Maps, YouTube, Gmail, and Google partner websites.
        <br />
        <br />
        <br />
      </Paragraph>

      <Button variant="link" onClick={() => setShowCases(!showCases)}>
        See key benefits
        <i className="fas fa-chevron-down fa-fw" />
      </Button>
      {showCases && <Cases />}

      <div className="d-flex mt-4 gap-2">
        <Button variant="primary" onClick={goStart}>
          START
        </Button>
        <Button variant="outline-primary" onClick={goHome}>
          HOME
        </Button>
      </div>
    </Container>
  );
};

export default LearnMore;
