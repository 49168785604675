import { useGetBusinessInfoQuery } from "@/data/api";
import { AccountInfo } from "@/data/types";
import { useData } from "@/hooks/useData";

export function useAccountInfo() {
  const { token, refreshToken } = useData();

  const { data = [] } = useGetBusinessInfoQuery({ token: token! }, { skip: !token || !refreshToken });
  const accountInfo: AccountInfo | undefined = data[0];
  const {
    business_name: businessName,
    business_location_id: businessLocationId,
    final_url: finalUrl,
    phone_number: phoneNumber
  } = accountInfo || {};

  return {
    businessName,
    businessLocationId,
    finalUrl,
    phoneNumber
  };
}
